import PhotoAlbum from "react-photo-album";
import { useState, useCallback, useEffect, useContext, useMemo } from "react";
import classNames from "classnames";
import { randomIntFromInterval } from "helpers/utils";
import Youtube from "components/Media/Youtube";
import DownloadMessage from "../Branding/download_message";
import {
  getCloudflareUrl,
  getFrameUrl,
} from "../../../../gallery_helpers/cloudflare_image";
import VideoJS from "components/Media/Videojs";
import { toast } from "react-toastify";
import getFrameData from "./frames_data";
import GalleryContext from "store/galleryContext";
import lockImage from "assets/images/illustrations/lock.png";

const pastelColors = [
  "#F7A4A4",
  "#FEBE8C",
  "#FFFBC1",
  "#B6E2A1",
  "#FEE2B3",
  "#FFA299",
  "#AD6989",
  "#562349",
  "#876445",
  "#CA965C",
  "#EEC373",
  "#F4DFBA",
];

const GalleryContent = ({
  images,
  frame,
  page,
  keyPrefix,
  eventId,
  eventData,
  onDownload,
  allowDownload,
  allowSingleDownload,
  liked,
  onLike,
  onComment,
  onClick,
  imageToolbarSubmitting,
  showLikeButton,
  albumSelection,
  downloadMessageProps,
  checkImageLocked,
  ...props
}) => {
  const { handleTrigger } = useContext(GalleryContext);
  const photos = useMemo(
    () =>
      images.map((img, idx) => {
        const originalHeight = Number(img.height);
        const originalWidth = Number(img.width);
        const ratio = originalWidth / originalHeight;
        var thumbnailHeight = originalHeight > 480 ? 480 : originalHeight;
        var thumbnailWidth = thumbnailHeight * ratio;

        var medHeight = originalHeight > 1080 ? 1080 : originalHeight;
        var medWidth = medHeight * ratio;
        var srcSet, src, height, width;
        const frameCF = getFrameData(eventId, null);
        const isLocked = checkImageLocked(page, idx);
        const uploadBy = img.collaborator_name;
        const isNotApproved = img.is_hidden;
        if (frame || frameCF) {
          var {
            src: medSrc,
            height: newHeight,
            width: newWidth,
          } = getFrameUrl({
            img_url: img.img_url,
            originalHeight: medHeight,
            originalWidth: medWidth,
            frame: frame || frameCF,
          });

          const newRatio = newWidth / newHeight;
          const newThumbnailWidth = thumbnailHeight * newRatio;
          const newMedWidth = medHeight * newRatio;

          height = medHeight;
          width = newMedWidth;
          src = medSrc;
          // thumbnail
          var { src: thumbnailSrc } = getFrameUrl({
            img_url: img.img_url,
            originalHeight: thumbnailHeight,
            originalWidth: thumbnailWidth,
            frame: frame || frameCF,
          });

          //original
          var { src: OriginalSrc } = getFrameUrl({
            img_url: img.img_url,
            originalHeight,
            originalWidth,
            frame: frame || frameCF,
          });
          img.img_with_frame = OriginalSrc;
          img.frame = true;

          srcSet = [
            {
              src: thumbnailSrc,
              height: thumbnailHeight,
              width: newThumbnailWidth,
            },
            {
              src: medSrc,
              height: medHeight,
              width: newMedWidth,
            },
          ];
        } else if (
          eventData &&
          eventId === "11348" &&
          eventData.branding?.show_watermark &&
          eventData.branding?.watermark_on_download &&
          eventData.branding?.img_url
        ) {
          const branding = eventData.branding;

          const medSrc = getCloudflareUrl({
            img_url: img.img_url,
            originalHeight: medHeight,
            originalWidth: medWidth,
            branding,
            frame,
          });
          const thumbnailSrc = getCloudflareUrl({
            img_url: img.img_url,
            originalHeight: thumbnailHeight,
            originalWidth: thumbnailWidth,
            branding,
            frame,
          });
          src = medSrc;
          height = medHeight;
          width = medWidth;
          srcSet = [
            { src: medSrc, height: medHeight, width: medWidth },
            {
              src: thumbnailSrc,
              height: thumbnailHeight,
              width: thumbnailWidth,
            },
          ];
        } else {
          src = img.med_url;
          height = medHeight;
          width = medWidth;
          srcSet = [
            { src: img.med_url, height: medHeight, width: medWidth },
            {
              src: img.thumbnail_url,
              height: thumbnailHeight,
              width: thumbnailWidth,
            },
          ];
        }
        return {
          src: src,
          isLiked: liked.includes(img.id),
          srcSet: srcSet,
          ...img,
          originalHeight,
          originalWidth,
          height: height,
          width: width,
          onDownload: onDownload,
          onLike: onLike,
          onComment: onComment,
          allowDownload,
          allowSingleDownload,
          liked,
          handleTrigger,
          element_key: `${keyPrefix}-${img.id}`,
          isLocked,
          uploadBy,
          isNotApproved,
        };
      }),
    [images, liked, frame]
  );

  const RenderPhotoWrapper = useCallback(
    (props) => {
      // console.log(props.photo.id);
      return <RenderPhoto {...props} />;
    },
    [showLikeButton, allowDownload, allowSingleDownload, albumSelection]
  );

  const RenderPhoto = ({ imageProps, photo }) => {
    const RETRY_LIMIT = 5;
    const {
      src,
      alt,
      srcSet,
      sizes,
      style,
      onClick,
      className,
      ...restImageProps
    } = imageProps;
    const {
      onDownload,
      onLike,
      onComment,
      allowDownload,
      allowSingleDownload,
      liked,
      isLocked,
      handleTrigger,
      uploadBy,
      isNotApproved,
    } = photo;
    const isLiked = photo.isLiked; //liked.includes(photo.id);
    const [retry, setRetry] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [isImgLoading, setIsImgLoading] = useState(true);
    const color =
      pastelColors[randomIntFromInterval(0, pastelColors.length - 1)];

    var videoId;
    var videoJsOptions;
    if (photo.mime_type === "video/youtube") {
      videoId = photo.img_url.split("/embed/")[1].split("?")[0];
      const videoUrl = new URL(photo.img_url);
      videoUrl.searchParams.append("rel", 0);
      videoUrl.searchParams.append("showinfo", 0);
      videoUrl.searchParams.append("controls", 0);
      videoUrl.searchParams.append("autohide", 1);

      videoJsOptions = {
        techOrder: ["youtube"],
        autoplay: false,
        controls: true,
        sources: [
          {
            src: videoUrl.href,
            type: "video/youtube",
          },
        ],
        youtube: { iv_load_policy: 3 },
      };
    }
    return (
      <div
        id={photo.id}
        style={{ ...style }}
        onClick={onClick}
        className="position-relative image-container"
      >
        {photo.mime_type === "video/youtube" ? (
          <>
            <Youtube
              videoId={videoId}
              height={"100%"}
              width={"100%"}
              icon="youtube"
              autoplay={false}
            />
            {/* <VideoJS options={videoJsOptions} /> */}
          </>
        ) : (
          <>
            <img
              key={`${photo.id}-${retry}`}
              className={className}
              style={{
                height: "100%",
                width: "100%",
              }}
              src={src}
              alt={alt}
              {...(srcSet ? { srcSet, sizes } : null)}
              {...restImageProps}
              onError={(e) => {
                if (retry < RETRY_LIMIT) {
                  console.log("retrying image load");
                  setRetry((p) => p + 1);
                } else console.log(e);
              }}
              onLoad={() => {
                setIsImgLoading(false);
              }}
            />
            {isImgLoading && (
              <div className="placeholder-glow position-absolute top-0 start-0 w-100 h-100">
                <div
                  className="placeholder w-100 h-100"
                  style={{ backgroundColor: color, opacity: 1 }}
                ></div>
              </div>
            )}
            {isLocked && (
              <div className="image-lock d-flex  flex-column justify-content-center align-items-center h-100 w-100">
                <img src={lockImage} />
                <div className="mt-2">UPGRADE TO UNLOCK</div>
              </div>
            )}

            <div className="image-options">
              {isNotApproved && (
                <div className="bg-danger text-light px-1">
                  <small>Not approved by Admin</small>
                </div>
              )}
              <div className="d-flex justify-content-start align-items-end h-100 gap-1">
                <div className="flex-grow-1 text-light p-1 px-md-2">
                  {uploadBy && "By " + uploadBy}
                </div>
                {allowSingleDownload && (
                  <button
                    className="img-options-button"
                    disabled={downloading}
                    onClick={async (e) => {
                      setDownloading(true);
                      const toastId = toast.loading("Downloading ⏳", {
                        position:
                          window.innerWidth > 500
                            ? toast.POSITION.TOP_RIGHT
                            : toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                      });
                      const startDownload = () =>
                        new Promise(async (resolve, reject) => {
                          try {
                            await onDownload(e, photo);
                            toast.update(toastId, {
                              render: "Downloading Success 👌",
                              type: toast.TYPE.SUCCESS,
                              isLoading: false,
                            });
                            setTimeout(() => {
                              toast.dismiss(toastId);
                            }, 2000);
                            resolve(true);
                          } catch (err) {
                            console.log(err);
                            toast.update(toastId, {
                              render: "Downloading Failed 🤯",
                              type: toast.TYPE.ERROR,
                              isLoading: false,
                            });
                            setTimeout(() => {
                              toast.dismiss(toastId);
                            }, 2000);
                            reject(false);
                          }
                        });
                      startDownload();

                      // toast.promise(startDownload(), {
                      //   pending: "Downloading ⏳",
                      //   success: "Downloading Success 👌",
                      //   error: "Downloading Failed 🤯",
                      // });
                      handleTrigger("download", { photo, eventData });
                      // downloadMessageProps.handleOnDownloadMessage(photo);
                      // await onDownload(e, photo);
                      setDownloading(false);
                    }}
                  >
                    {downloading ? (
                      <i className="mdi mdi-loading mdi-spin fs-3" />
                    ) : (
                      <i className="mdi mdi-download fs-3" />
                    )}
                  </button>
                )}
                {showLikeButton && (
                  <button
                    style={isLiked ? { opacity: 1 } : {}}
                    className="img-album-options-button"
                    onClick={(e) => onLike(e, photo)}
                  >
                    <i
                      className={classNames(
                        "mdi  fs-3",
                        isLiked ? "mdi-heart liked" : "mdi-heart-outline"
                      )}
                    />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <DownloadMessage {...downloadMessageProps} eventData={eventData} />
      <PhotoAlbum
        renderPhoto={RenderPhotoWrapper}
        photos={photos}
        layout="rows"
        renderRowContainer={renderRowContainer}
        renderColumnContainer={renderColumnContainer}
        columns={(containerWidth) => {
          if (containerWidth < 400) return 3;
          if (containerWidth < 800) return 3;
          return 4;
        }}
        onClick={(event, photo, index) => onClick(index)}
        spacing={0}
        {...props}
      />
    </>
  );
};

const renderRowContainer = ({ rowContainerProps, children }) => {
  return (
    <div {...rowContainerProps}>
      {children.map((element) => (
        <element.type {...element.props} key={element.props.photo.element_key}>
          {element.children}
        </element.type>
      ))}
    </div>
  );
};

const renderColumnContainer = ({ columnContainerProps, children }) => {
  return (
    <div {...columnContainerProps}>
      {children.map((element) => (
        <element.type {...element.props} key={element.props.photo.element_key}>
          {element.children}
        </element.type>
      ))}
    </div>
  );
};

export default GalleryContent;
