import {
  getCloudflareUrl,
  getFrameUrl,
} from "../../../../../gallery_helpers/cloudflare_image";
import { ACTIVITY } from "../../enums";
import { useState, useMemo, useContext, useEffect } from "react";
import GalleryContext from "store/galleryContext";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const useLightBox = (imageData, eventData, eventId, filterPeople) => {
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    if (filterPeople) {
      setIndex(-1);
    }
  }, [filterPeople]);

  const { handleActivity } = useContext(GalleryContext);
  var frame;
  const photos = useMemo(() => {
    if (imageData) {
      var images = [];

      imageData.forEach((pageData) => {
        images.push(...pageData.data.image_list);
      });
      frame = imageData[0]?.data?.frame;

      const photos = images.map((img, idx) => {
        var originalHeight = Number(img.height);
        var originalWidth = Number(img.width);
        const ratio = originalWidth / originalHeight;
        const thumbnailHeight = originalHeight > 480 ? 480 : originalHeight;
        const thumbnailWidth = thumbnailHeight * ratio;

        const medHeight = originalHeight > 1080 ? 1080 : originalHeight;
        const medWidth = medHeight * ratio;

        var srcSet, src, height, width;
        if (frame) {
          var {
            src: medSrc,
            height: newHeight,
            width: newWidth,
          } = getFrameUrl({
            img_url: img.img_url,
            originalHeight: medHeight,
            originalWidth: medWidth,
            frame,
          });

          const newRatio = newWidth / newHeight;
          const newThumbnailWidth = thumbnailHeight * newRatio;
          const newMedWidth = medHeight * newRatio;

          height = medHeight;
          width = newMedWidth;
          src = medSrc;
          // thumbnail
          var { src: thumbnailSrc } = getFrameUrl({
            img_url: img.img_url,
            originalHeight: thumbnailHeight,
            originalWidth: thumbnailWidth,
            frame,
          });

          //original
          var { src: OriginalSrc } = getFrameUrl({
            img_url: img.img_url,
            originalHeight,
            originalWidth,
            frame,
          });
          img.img_with_frame = OriginalSrc;
          img.frame = true;

          srcSet = [
            {
              src: medSrc,
              height: medHeight,
              width: newMedWidth,
            },
            {
              src: thumbnailSrc,
              height: thumbnailHeight,
              width: newThumbnailWidth,
            },
          ];
        } else if (
          eventData &&
          eventId === "11348" &&
          eventData.branding?.show_watermark &&
          eventData.branding?.watermark_on_download &&
          eventData.branding?.img_url
        ) {
          const branding = eventData.branding;
          const medSrc = getCloudflareUrl({
            img_url: img.img_url,
            originalHeight: medHeight,
            originalWidth: medWidth,
            branding,
            frame,
          });
          const thumbnailSrc = getCloudflareUrl({
            img_url: img.img_url,
            originalHeight: thumbnailHeight,
            originalWidth: thumbnailWidth,
            branding,
            frame,
          });
          src = medSrc;
          height = medHeight;
          width = medWidth;
          srcSet = [
            { src: medSrc, height: medHeight, width: medWidth },
            {
              src: thumbnailSrc,
              height: thumbnailHeight,
              width: thumbnailWidth,
            },
          ];
        } else {
          src = img.med_url;
          height = medHeight;
          width = medWidth;
          srcSet = [
            { src: img.med_url, height: medHeight, width: medWidth },
            {
              src: img.thumbnail_url,
              height: thumbnailHeight,
              width: thumbnailWidth,
            },
          ];
        }
        return {
          src: src,
          srcSet: srcSet,
          ...img,
          height: height,
          width: width,
          originalHeight: originalHeight,
          originalWidth: originalWidth,
          title: !isMobile && img.name,
          description: isMobile && img.name,
        };
      });
      return photos;
    } else {
      return [];
    }
  }, [imageData]);

  const handleSetIndex = (index, noTrack) => {
    try {
      photos?.length > 0 &&
        index !== -1 &&
        !noTrack &&
        handleActivity(ACTIVITY.IMAGE_VIEW, photos[index].id);
    } catch (e) {
      console.log(e);
    }
    setIndex(index);
  };
  return { index, handleSetIndex, photos, frame };
};

export default useLightBox;
