import { useCallback, useRef, useState } from "react";
// import * as tfFaceDetection from "@tensorflow-models/face-detection";
import { Camera } from "react-camera-pro";
import classNames from "classnames";
import captureSelfieAnimation from "assets/images/animations/capture-selfie.json";
import captureSelfieDoneAnimation from "assets/images/animations/capture-selfie-done.json";
import loader from "assets/images/icons/loader.svg";
import Loader from "components/Loader";
import validateSelfie from "./image_helper/validate_selfie";
import { useTranslation } from "react-i18next";

let width = window.innerWidth - 50;
let height = window.innerHeight;

const SELFIE_STATUS = {
  IDLE: 0,
  CHECKING: 1,
  VALID: 2,
  INVALID: 3,
};

const getOverlayAnimation = (cameraReady, frame, selfieStatus) => {
  if (frame) {
    if (selfieStatus === SELFIE_STATUS.CHECKING)
      return (
        <lottie-player
          src={JSON.stringify(captureSelfieAnimation)}
          background="transparent"
          speed="1"
          style={{ width: "auto", height: "305px" }}
          class={classNames("animation", { "d-none": frame })}
          // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
          loop
          autoplay
        ></lottie-player>
      );
    else if (selfieStatus == SELFIE_STATUS.VALID)
      return (
        <lottie-player
          src={JSON.stringify(captureSelfieDoneAnimation)}
          background="transparent"
          speed="1"
          style={{ width: "auto", height: "305px" }}
          class={classNames("animation", { "d-none": !frame })}
          // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
          loop
          autoplay
        ></lottie-player>
      );
    else return <></>;
  } else if (cameraReady) {
    return (
      <lottie-player
        src={JSON.stringify(captureSelfieAnimation)}
        background="transparent"
        speed="1"
        style={{ width: "auto", height: "305px" }}
        class={classNames("animation", { "d-none": frame })}
        // rendererSettings={JSON.stringify({ viewBoxSize: "500 0 2400 590" })}
        loop
        autoplay
      ></lottie-player>
    );
  } else {
    return <Loader className="h-100 align-items-center" />;
  }
};

export default function FaceDetectSelfie({ onDone, onClose }) {
  if (width > 1000) {
    width = 640;
  }
  const { t } = useTranslation();
  const getHeader = (frame, selfieStatus) => {
    if (frame) {
      if (selfieStatus === SELFIE_STATUS.INVALID)
        return (
          <>
            <h4>{t("Capture Selfie")}</h4>
            <strong style={{ textAlign: "center" }} className="blink-warning">
              <i className="mdi mdi-alert me-2" />
              Go to Brighter Place
            </strong>
            <div>and</div>
            <strong className="text-danger">Retake Selfie</strong>
          </>
        );
      else if (selfieStatus === SELFIE_STATUS.VALID) {
        return (
          <>
            <h4>
              <div className="text-success">
                <i className="mdi mdi-check-decagram me-2 " />
                {t("Selfie Verified")}
              </div>
            </h4>
            <strong>{t("Click Done to confirm")}</strong>
            <div>{t("or")}</div>
            <strong>{t("Click Retake to reject")} </strong>
          </>
        );
      } else
        return (
          <>
            <h4>{t("Capture Selfie")}</h4>
            <strong style={{ textAlign: "center" }} className="blink">
              {t("Keep Face in the Center")}
            </strong>
            <div>{t("and")}</div>
            <strong>{t("Press Capture")}</strong>
          </>
        );
    } else {
      return (
        <>
          <h4>{t("Capture Selfie")}</h4>
          <strong style={{ textAlign: "center" }} className="blink">
            {t("Keep Face in the Center")}
          </strong>
          <div>{t("and")}</div>
          <strong>{t("Press Capture")}</strong>
        </>
      );
    }
  };

  const capturedFrameRef = useRef();
  const camera = useRef(null);
  const [capture, setCapture] = useState(false);
  const [isFDReady, setIsFDReady] = useState(true);
  const [faceCentered, setFaceCentered] = useState(true);
  const [facingMode, setFacingMode] = useState(
    localStorage.getItem("facingMode") || "user"
  );
  const [refreshCamera, setRefreshCamera] = useState(0);
  const [numberOfCameras, setNumberOfCameras] = useState(0);

  const stateRef = useRef();
  stateRef.current = capture;
  const [frame, setFrame] = useState();
  const [selfieStatus, setSelfieStatus] = useState(SELFIE_STATUS.IDLE);
  const handleFrameValidation = (valid) => {
    console.log("selfie valid:", valid);
    valid
      ? setSelfieStatus(SELFIE_STATUS.VALID)
      : setSelfieStatus(SELFIE_STATUS.INVALID);
  };
  const handleOnCapture = () => {
    setCapture(true);
    const frame = camera.current.takePhoto();
    setFrame(frame);
    setSelfieStatus(SELFIE_STATUS.VALID);

    // validateSelfie(frame, handleFrameValidation);
    // handleOnStop(true, frame);
  };

  const handleOnDone = () => {
    handleOnStop(true, frame);
  };

  const handleOnStop = (live, frame) => {
    setCapture(false);
    onDone(live, [frame]);
  };

  const handleOnRetake = () => {
    setCapture(false);
    setFrame();
  };

  const SVGIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" height={"100%"} width={"100%"}>
      <defs>
        <mask id="image-mask">
          <rect width="100%" height="100%" fill="#fff" />

          {/* <ellipse
            style={{ transform: "translate(50%,50%)" }}
            cx="0"
            cy="0"
            rx="115"
            ry="130"
          /> */}
          <rect
            width="64%"
            height="90%"
            style={{
              transform: "translate(0%, 10px)",
            }}
            x="18%"
            y="0"
            rx="120"
            ry="120"
          ></rect>
        </mask>
      </defs>
      <rect
        className="webcam-mask"
        x="0"
        y="0"
        width="100%"
        height="100%"
        mask="url(#image-mask)"
        fillOpacity="1"
        // fill="#ffff"
      />
    </svg>
  );

  const handleNumberOfCameras = (n) => {
    // alert(n);
    setNumberOfCameras(n);
  };

  const handleOnChangeCamera = () => {
    camera.current.switchCamera();
    facingMode === "user"
      ? localStorage.setItem("facingMode", "environment")
      : localStorage.setItem("facingMode", "user");
    setRefreshCamera((p) => p + 1);
  };

  const getFooter = useCallback(
    (frame, selfieStatus) => {
      if (frame) {
        if (selfieStatus === SELFIE_STATUS.VALID) {
          return (
            <div className="d-flex gap-2 mt-5 px-4 justify-content-between">
              <>
                <div onClick={handleOnRetake}>
                  <span className="fs-5 px-3 p-2 badge bg-secondary rounded-pill ">
                    <i className="mdi mdi-camera-retake  me-2 "></i>
                    {t("Retake")}
                  </span>
                </div>

                <div onClick={handleOnDone}>
                  <span className="fs-5 px-3 p-2 badge rounded-pill bg-success">
                    <i className="mdi mdi-check-circle  me-2 "></i>
                    {t("Done")}
                  </span>
                </div>
              </>
            </div>
          );
        } else {
          return (
            <div className="d-flex gap-2 mt-5 px-4 justify-content-end">
              <div onClick={handleOnRetake}>
                <span className="fs-5 px-3 p-2 badge bg-secondary rounded-pill ">
                  <i className="mdi mdi-camera-retake  me-2 "></i>
                  Retake
                </span>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="d-flex gap-2 mt-4 px-2 justify-content-center">
            {/* <div
              onClick={handleOnCapture}
              disabled={capture || !isFDReady || !faceCentered}
            >
              <img
                width="65"
                height="65"
                src="https://img.icons8.com/fluency/48/unchecked-radio-button.png"
                alt="unchecked-radio-button"
              />
            </div> */}
            <button
              className="btn btn-gallery-primary"
              onClick={handleOnCapture}
            >
              <i className="mdi mdi-camera  me-2"></i>
              {t("Capture")}
            </button>
          </div>
        );
      }
      // {numberOfCameras > 1 ? (
      //   <i
      //     className="mdi mdi-camera-flip mdi-36px mx-3"
      //     onClick={handleOnChangeCamera}
      //   />
      // ) : (
      //   <div style={{ width: "70px" }}></div>
      // )}
    },
    [frame, selfieStatus]
  );

  return (
    <div className="d-flex flex-column ">
      <div className="p-3 d-flex justify-content-center flex-column align-items-center position-relative">
        {getHeader(frame, selfieStatus)}

        <div
          className="position-absolute end-0 top-0 p-3 fs-2"
          onClick={onClose}
        >
          <i className="mdi mdi-close" style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className="position-relative" style={{ height: "300px" }}>
        <div className="overlay h-100">{SVGIcon}</div>

        {!isFDReady && (
          <>
            <div
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{ backgroundColor: "rgb(0 0 0 / 38%)", zIndex: 300 }}
            ></div>
            <div
              style={{ zIndex: 300 }}
              className="position-absolute top-50 start-50 translate-middle"
            >
              <img src={loader} alt={"loader"} />
            </div>
          </>
        )}

        <div
          style={{ zIndex: 300 }}
          className="position-absolute top-50 start-50 translate-middle h-100 w-100"
        >
          {getOverlayAnimation(camera.current, frame, selfieStatus)}
        </div>
        <div>
          {frame ? (
            <img
              ref={capturedFrameRef}
              className="captured-frame"
              src={frame}
            />
          ) : (
            <Camera
              ref={camera}
              aspectRatio={4 / 3}
              facingMode={facingMode}
              numberOfCamerasCallback={handleNumberOfCameras}
              errorMessages={{
                noCameraAccessible: (
                  <div className="camera-access-error">
                    <div className="">
                      <img
                        width="48"
                        height="48"
                        src="https://img.icons8.com/fluency/48/general-warning-sign.png"
                        alt="general-warning-sign"
                      />

                      <div>Allow Camera Permissions and Reload the page</div>
                    </div>
                    <button className="btn btn-gallery-primary mt-3 py-1">
                      <a
                        href="https://youtube.com/shorts/4wv4YGPsxSM?feature=share"
                        target="_blank"
                      >
                        Steps to allow camera permission{" "}
                        <i className="mdi mdi-" />
                      </a>
                    </button>
                  </div>
                ),

                permissionDenied:
                  "Permission denied. Please refresh and give camera permission.",
                switchCamera:
                  "It is not possible to switch camera to different one because there is only one video device accessible.",
                canvas: "Canvas is not supported.",
              }}
            />
          )}
        </div>
      </div>

      {getFooter(frame, selfieStatus)}
    </div>
  );
}
