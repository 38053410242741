import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Progress,
  Modal,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Button,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

import { useUploadBucket } from "services/useUpload";
import { UploadProvider } from "store/image/uploadContext";
import { uploadWorker } from "helpers/upload_helper";
import swal from "sweetalert";
import { UPLOAD_STATUS } from "services/useUpload";
import { getFileUploadStatus } from "services/useUpload";
import { formatBytes } from "helpers/utils";
import { removeSlash } from "helpers/utils";
import UserContext from "store/guest_user/userContext";
import { useParams } from "react-router-dom";

const RETRY_LIMIT = 5;

const Upload = ({ children }) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [startUpload, setStartUpload] = useState(null);
  const [uploadDone, setUploadDone] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [uploadedQueue, setUploadedQueue] = useState([]);

  ////////////////////////////// UPLOAD TO BUCKET ///////////////////////////
  const [failedUploads, setFailedUploads] = useState([]);
  const [uploadBucketPayload, setUploadBucketPayload] = useState(null);
  const {
    listUploadBatch,
    isStopped,
    stopUpload,
    uploadBatchIndex,
    retryBatch,
    resetUploads,
    notification,
    notificationCallback,
    showUploadProgress,
    duplicateResolving,
    isCheckingDuplicates,
  } = useUploadBucket(uploadWorker, uploadBucketPayload, startUpload);

  var index = uploadBatchIndex !== -1 ? uploadBatchIndex : 0;
  const { progress, uploadStatus, error, data, size } = listUploadBatch[index];

  useEffect(() => {
    if (uploadDone) {
      console.log(`Done uploading....`);
      setStartUpload(false);
      const uploaded = uploadQueue.pop();
      setUploadedQueue([
        ...uploadedQueue,
        {
          files: uploaded,
          uploadPayload: { ...uploadBucketPayload },
          progress: [...progress],
          uploadStatus: [...uploadStatus],
        },
      ]);
      setUploadQueue([...uploadQueue]);
      //call trigger event
      // postUploadAllDone({ event_id: eventId, process_info_id: processId });
      // goToEvent();
    }
  }, [uploadDone]);

  useEffect(() => {
    if (uploadQueue.length > 0) {
      const queueSelectedFiles = uploadQueue.pop();
      // createUploadPayload(queueSelectedFiles);
      setselectedFiles(queueSelectedFiles);
    }
  }, [uploadQueue, startUpload]);

  useEffect(() => {
    if (selectedFiles) {
      if (totalUploadStatus === UPLOAD_STATUS.UPLOAD_STOP) {
        resetUploads();
      }
      createUploadPayload(selectedFiles);
    }
  }, [selectedFiles]);

  const [eventId, setEventId] = useState();
  const [user, setUser] = useState();
  const [show, setShow] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [totalUploadSize, setTotalUploadSize] = useState(0);
  const [totalUploadFiles, setTotalUploadFiles] = useState(0);
  const [targetUploadFiles, setTargetUploadFiles] = useState(0);
  // const [autoCollection, setAutoCollection] = useState();
  const [collectionId, setCollectionId] = useState();

  const handleAcceptedFiles = async (files, collectionId, _user, _eventId) => {
    setUser(_user);
    setEventId(_eventId);
    console.log("file added");
    if (collectionId !== -1 || collectionId !== "-1")
      setCollectionId(collectionId);
    parseacceptedFiles(files);
  };
  async function parseacceptedFiles(files) {
    if (files.length === 0) {
      return;
    }
    setShow(true);
    setTargetUploadFiles(files.length);
    if (uploadDone) {
      var size = 0;
      setTotalUploadSize(0);
    } else {
      var size = totalSize;
    }

    files.map((file) => {
      size = size + file.size;
      // Object.assign(file, {
      //   // preview: URL.createObjectURL(file),
      //   preview: null,
      //   formattedSize: formatBytes(file.size),
      //   progress: 0,
      //   // size: file.size,
      // });
    });

    console.log(`Total size ${formatBytes(size)}`);
    setUploadDone(false);
    setStartUpload(false);
    if (!open) {
      toggle();
    }
    // setUploadReady(true);
    setUploadQueue([...uploadQueue, files]);
    // setRetry(0);
  }
  const [isTriggered, setIsTriggered] = useState(false);
  const [totalUploadStatus, setTotalUploadStatus] = useState(
    UPLOAD_STATUS.IDLE
  );

  useEffect(() => {
    setShow(showUploadProgress ? true : false);
  }, [showUploadProgress]);

  useEffect(() => {
    var timeoutId;
    if (listUploadBatch.length > 0) {
      var _totalUploadStatus = [];
      var _totalProgress = 0;
      var _totalSize = 0;
      listUploadBatch.map((item) => {
        _totalUploadStatus.push(item.batchUploadStatus);
        _totalProgress += item.batchProgress;
        _totalSize += item.batchSize;
      });
      const totalProgressPercentage = _totalProgress / _totalSize;
      const fileUploadStatus = getFileUploadStatus(_totalUploadStatus);
      setTotalUploadStatus(fileUploadStatus);
      if (fileUploadStatus === UPLOAD_STATUS.UPLOADED && !duplicateResolving) {
        setIsTriggered(false);
        // timeoutId = setTimeout(() => {
        //   resetUploads();
        //   setShow(false);
        // }, 15000);
      }
    }
    return () => clearTimeout(timeoutId);
  }, [listUploadBatch]);

  useEffect(() => {
    if (uploadStatus.length > 0) {
      // console.log(progress);

      var tempUploadDone = true;
      var uploadError = false;
      var uploadedFiles = 0;
      var tempUploadStopped = false;
      var _failedUploads = [];
      var uploadedSize = 0;
      uploadStatus.forEach((element, idx) => {
        if (
          element === UPLOAD_STATUS.UPLOADING ||
          element === UPLOAD_STATUS.IDLE
        ) {
          tempUploadDone = false;
        } else if (element === UPLOAD_STATUS.UPLOAD_ERROR) {
          uploadError = true;
          _failedUploads.push({
            request: data[idx].request,
            error: error[idx],
          });
        } else if (element === UPLOAD_STATUS.UPLOADED) {
          uploadedFiles += 1;
          progress[idx] = size[idx];
        } else if (element === UPLOAD_STATUS.UPLOAD_STOP) {
          tempUploadStopped = true;
        }
        uploadedSize += progress[idx];
      });
      setTotalUploadSize(uploadedSize);
      // setselectedFiles([...selectedFiles]);
      // console.log(
      //   `tempUploadDone:${tempUploadDone} uploadError:${uploadError} tempUploadStopped:${tempUploadStopped}`
      // );
      if (tempUploadDone & uploadError) {
        const retryUploadData = [];
        // console.log(`upload done, failed ${failedUploads.length}`);
        setFailedUploads(_failedUploads);
        // setShowMessage(true);
        setUploadDone(true);
        // }
      } else if (tempUploadDone) {
        setUploadDone(true);
      }
    }
    setTotalUploadFiles(uploadedFiles || 0);
    // console.log(`Total uploaded size ${formatBytes(uploadedSize)}`);
  }, [uploadStatus, isStopped]);

  // TODO: make complete process async

  const createUploadPayload = async (queueSelectedFiles) => {
    if (!uploadDone && queueSelectedFiles.length > 0) {
      setShow(true);

      var newUploadData = [];
      const guestId = user.uid;
      const collectionId = -1;
      queueSelectedFiles.forEach((item, index) => {
        const path = "/" + removeSlash(item.path);
        const bucketKey = `events/${eventId}/${guestId}/raw`;
        newUploadData.push({
          file: item,
          filename: item.name,
          type: item.type,
          path: path,
          bucketKey,
          userId: guestId,
          callback: "1",
          eventId,
          isGallery: true,
          attempt: 1,
          collectionId,
          collectionIds: [collectionId],
          isGuestUpload: true,
        });
      });

      setUploadBucketPayload(newUploadData);
      setStartUpload(1);
      // }
    }
    // setUploadData(newUploadData);
  };

  const handleOnRetryUpload = () => {
    console.log(`Retrying upload`);
    const files = failedUploads.map((failed) => failed.request.file);
    setShowMessage(false);
    handleAcceptedFiles(files, true);
    setFailedUploads([]);
  };
  //////////////////////////////////////////////////////////////////////////////////
  /**
   * Formats the size
   */

  const [open, setOpen] = useState(false);
  const toggle = () => {
    var body = document.getElementById("upload-body");
    var form = document.getElementById("upload-form");

    if (body) {
      console.log(open);
      if (form.classList.contains("close-upload")) {
        form.classList.remove("close-upload");

        // form.style.top = `calc(100vh - ${height + 61}px)`;
        // body.style.display = "block";
        body.style.padding = null;
        body.style.height = "auto";

        setOpen(true);
      } else {
        form.classList.add("close-upload");
        // form.style.top = "calc(100vh - 61px)";
        // body.style.display = "none";
        body.style.height = "0";
        body.style.padding = "0";
        setOpen(false);
      }
    } else {
      console.log("Upload Body not found");
    }
  };

  var header;
  const getHeader = useCallback(() => {
    switch (totalUploadStatus) {
      case UPLOAD_STATUS.IDLE:
        header = (
          <div className="flex-grow-1 d-flex align-items-center gap-2">
            <div>Checking Duplicate Files</div>
            <i className="mdi mdi-content-duplicate font-size-16 align-middle me-2"></i>
          </div>
        );
        return header;

      case UPLOAD_STATUS.UPLOADING:
        header = (
          <div className="flex-grow-1 d-flex align-items-center gap-2">
            <div>Uploading Image V1</div>
            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          </div>
        );
        return header;
      case UPLOAD_STATUS.UPLOADED:
        header = (
          <div className="flex-grow-1 d-flex align-items-center gap-2">
            <div>Uploading Successful</div>
            <i style={{ color: "green" }} className="mdi mdi-check-circle"></i>
          </div>
        );
        return header;

      case UPLOAD_STATUS.UPLOAD_ERROR:
        header = (
          <div className="flex-grow-1 d-flex align-items-center gap-2">
            <div>Uploading Failed</div>
            <i
              style={{ color: "#f94747" }}
              className="mdi mdi-close-circle"
            ></i>
          </div>
        );
        return header;
      case UPLOAD_STATUS.UPLOAD_STOP:
        header = (
          <div className="flex-grow-1 d-flex align-items-center gap-2">
            <div>Uploading Stopped</div>
            <i
              style={{ color: "#f94747" }}
              className="mdi mdi-upload-off fs-3"
            ></i>
          </div>
        );
        return header;
      case UPLOAD_STATUS.UPLOAD_STOPPING:
        header = (
          <div className="flex-grow-1 d-flex align-items-center gap-2">
            <div>Stopping(finishing up in-process files)</div>
          </div>
        );
        return header;
    }
  }, [totalUploadStatus]);

  const getBody = () => {
    if (totalUploadStatus === UPLOAD_STATUS.IDLE) {
      return (
        <div>
          <div>Checking Duplicate Files</div>
          <small>Rename the files if they are different</small>
        </div>
      );
    } else {
      return listUploadBatch.map((batch, idx) => (
        <div key={idx} className="mt-1">
          {getUploadStatus(batch, idx)}
        </div>
      ));
    }
  };
  const tooltips = {
    [UPLOAD_STATUS.IDLE]: "Waiting to upload",
    [UPLOAD_STATUS.UPLOADING]: "Upload in progress",
    [UPLOAD_STATUS.UPLOADED]: "Upload Successfull",
    [UPLOAD_STATUS.UPLOAD_ERROR]: "Some or All files failed to upload",
    [UPLOAD_STATUS.UPLOAD_STOP]: "Upload Stopped by user",
    [UPLOAD_STATUS.UPLOAD_STOPPING]:
      "Upload stopping, wait for inprogress files",
    failedFiles: "View Failed Files",
    retryUpload: "Retry Failed files Upload",
  };
  const ToolTip = ({ id, value }) => (
    <UncontrolledTooltip key={id} placement="top" target={`#${id}`}>
      {tooltips[value]}
    </UncontrolledTooltip>
  );

  const getUploadStatus = useCallback(
    (batch, idx) => {
      const UploadText = () => (
        <div className="d-flex gap-3">
          <p className="mb-0">
            {formatBytes(batch.batchProgress)}/ {formatBytes(batch.batchSize)}
          </p>
          {""} | {""}
          <p className="mb-0 ">
            {
              batch.uploadStatus.filter((i) => i === UPLOAD_STATUS.UPLOADED)
                .length
            }{" "}
            / {batch.uploadStatus.length}
          </p>
        </div>
      );
      const UploadIcon = () => (
        <>
          <div className="d-flex  align-item-center">
            <i className="mdi mdi-file-document-multiple-outline fs-2 align-middle" />
          </div>
        </>
      );

      const UploadProgress = () => (
        <Progress
          className="progress flex-grow-1"
          // color="primary"
          value={(100 * batch.batchProgress) / batch.batchSize}
        ></Progress>
      );

      const Percentage = () => (
        <div>{Math.round((100 * batch.batchProgress) / batch.batchSize)}%</div>
      );

      switch (batch.batchUploadStatus) {
        case UPLOAD_STATUS.UPLOADING:
          return (
            <>
              <ToolTip id={"UPLOADING"} value={UPLOAD_STATUS.UPLOADING} />
              <div className="d-flex gap-2 align-items-center uploading">
                <UploadIcon />
                <div className="d-flex flex-column flex-grow-1 justify-content-center me-2">
                  <div className="d-flex flex-column gap-2">
                    <UploadProgress />
                    <div className="d-flex justify-content-between  upload-details">
                      <UploadText />
                      <Percentage />
                    </div>
                  </div>
                </div>
                <i id="UPLOADING" className="mdi mdi-loading mdi-spin fs-4"></i>
              </div>
            </>
          );
        case UPLOAD_STATUS.IDLE:
          return (
            <>
              <ToolTip id={"IDLE"} value={UPLOAD_STATUS.IDLE} />
              <div className="d-flex gap-2 align-items-center idle">
                <UploadIcon />
                <div className="d-flex flex-column flex-grow-1 justify-content-center me-2">
                  <div className="d-flex flex-column gap-2">
                    <UploadProgress />
                    <div className="d-flex justify-content-between  upload-details">
                      <div className="flex-grow-1">
                        {`Waiting to upload ${
                          batch.uploadStatus.length
                        } files     |     ${formatBytes(batch.batchSize)}`}
                      </div>
                      <Percentage />
                    </div>
                  </div>
                </div>
                <i
                  id="IDLE"
                  className="mdi mdi-timer-sand fs-4 align-middle fs-4"
                />
              </div>
            </>
          );
        case UPLOAD_STATUS.UPLOAD_ERROR:
          var Details;

          if (totalUploadStatus === UPLOAD_STATUS.UPLOAD_ERROR) {
            Details = () => (
              <>
                <ToolTip id={"retryUpload"} value={"retryUpload"} />
                <ToolTip id={"failedFiles"} value={"failedFiles"} />
                <div id="retryUpload" onClick={() => retryBatch(idx)}>
                  <i className="mdi mdi-refresh fs-3 align-middle" />{" "}
                </div>
                <div id="failedFiles" onClick={() => showFailedFiles(idx)}>
                  <i className="mdi mdi-information-outline fs-3 align-middle" />{" "}
                </div>
              </>
            );
          } else {
            Details = () => (
              <>
                <ToolTip
                  id={"UPLOAD_ERROR"}
                  value={UPLOAD_STATUS.UPLOAD_ERROR}
                />

                <div id="UPLOAD_ERROR">
                  <i className="mdi mdi-alert-octagon-outline fs-3" />
                </div>
              </>
            );
          }
          return (
            <>
              <div className="d-flex gap-2 align-items-center stop">
                <UploadIcon />
                <div className="d-flex flex-column flex-grow-1 justify-content-center me-2">
                  <div className="d-flex flex-column gap-2">
                    <UploadProgress />
                    <div className="d-flex justify-content-between  upload-details">
                      <UploadText />
                      <Percentage />
                    </div>
                  </div>
                </div>
                <Details />
              </div>
            </>
          );
        case UPLOAD_STATUS.UPLOADED:
          return (
            <>
              <ToolTip id={"UPLOADED"} value={UPLOAD_STATUS.UPLOADED} />
              <div className="d-flex gap-2 align-items-center">
                <UploadIcon />
                <div className="d-flex flex-column flex-grow-1 justify-content-center me-2">
                  <div className="d-flex flex-column gap-2">
                    <UploadProgress />
                    <div className="d-flex justify-content-between  upload-details">
                      <UploadText />
                      <Percentage />
                    </div>
                  </div>
                </div>
                <i
                  id="UPLOADED"
                  style={{ color: "green" }}
                  className="mdi mdi-check-circle fs-4"
                ></i>
              </div>
            </>
          );
        case UPLOAD_STATUS.UPLOAD_STOP:
          return (
            <>
              <ToolTip id={"UPLOAD_STOP"} value={UPLOAD_STATUS.UPLOAD_STOP} />
              <div className="d-flex gap-2 align-items-center stop">
                <UploadIcon />
                <div className="d-flex flex-column flex-grow-1 justify-content-center me-2">
                  <div className="d-flex flex-column gap-2">
                    <UploadProgress />
                    <div className="d-flex justify-content-between  upload-details">
                      <div className="flex-grow-1">
                        Upload {batch.uploadStatus.length} files |{" "}
                        {formatBytes(batch.batchSize)} Stopped
                      </div>
                      <Percentage />
                    </div>
                  </div>
                </div>
                <i
                  id="UPLOAD_STOP"
                  className="mdi mdi-upload-off fs-4 align-middle fs-2"
                />
              </div>
            </>
          );
        case UPLOAD_STATUS.UPLOAD_STOPPING:
          return (
            <>
              <ToolTip
                id={"UPLOAD_STOPPING"}
                value={UPLOAD_STATUS.UPLOAD_STOPPING}
              />
              <div className="d-flex gap-2 align-items-center">
                <UploadIcon />
                <div className="d-flex flex-column flex-grow-1 justify-content-center me-2">
                  <div className="d-flex flex-column gap-2">
                    <UploadProgress />
                    <div className="d-flex justify-content-between  upload-details">
                      <div className="flex-grow-1">
                        {batch.uploadStatus.length} files |{" "}
                        {formatBytes(batch.batchSize)} Stopping, waiting for
                        inprogress uploads
                      </div>
                      <Percentage />
                    </div>
                  </div>
                </div>
                <i
                  id="UPLOAD_STOPPING"
                  className="mdi mdi-upload-off fs-4 align-middle fs-2"
                />
              </div>
            </>
          );
      }
    },
    [listUploadBatch, totalUploadStatus]
  );

  const warnStopUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (
      totalUploadStatus === UPLOAD_STATUS.UPLOAD_STOP ||
      totalUploadStatus === UPLOAD_STATUS.UPLOADED ||
      totalUploadStatus === UPLOAD_STATUS.UPLOAD_ERROR
    ) {
      resetUploads();
      setShow(false);
    } else {
      swal({
        title: "Are you sure you want to stop Uploading?",
        text: "Uploading will be stopped after the files inprogress will get uploaded",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (ok) => {
        if (ok) {
          stopUpload();
          // setUploadStopCalled(true);
        }
      });
    }
  };
  const [failedDetailsBatchIndex, setFailedDetailsBatchIndex] = useState(0);
  const showFailedFiles = (index) => {
    setShowMessage(true);
    setFailedDetailsBatchIndex(index);
  };

  return (
    <UploadProvider
      value={{
        handleAcceptedFiles,
        // handleOnUploadBucket,
        startUpload: false,
        // checkDriveProgress,
        isUploadDialogOpen: show,
        totalUploadFiles,
        targetUploadFiles,
        totalUploadStatus,
        numUploadBatch: listUploadBatch.length,
      }}
    >
      <Modal isOpen={isCheckingDuplicates} centered>
        <ModalHeader>
          <h4>Checking Duplicate Files</h4>
        </ModalHeader>
        <ModalBody>
          <div>
            <Card>
              <CardBody className="d-flex flex-column align-items-center justify-content-center">
                <lottie-player
                  src="https://assets2.lottiefiles.com/private_files/lf30_hhb5tl1k.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "auto", height: "300px" }}
                  rendererSettings={{ viewBoxSize: "700 0 2400 590" }}
                  loop
                  autoplay
                ></lottie-player>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
      {/* {<DuplicateFilesNotification {...duplicateFilesNotificationProps} />} */}
      <div>
        {show && (
          <div className="upload-form close-upload " id="upload-form">
            <Card>
              <CardHeader
                onClick={toggle}
                className="d-flex align-items-center gap-2"
              >
                {getHeader()}
                {true ? (
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-chevron-down fs-3"></i>
                  </div>
                ) : (
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-up"></i>
                  </div>
                )}
                <button
                  className="btn btn-primary p-0 flex-shrink-0"
                  disabled={totalUploadStatus === UPLOAD_STATUS.UPLOAD_STOPPING}
                >
                  <i
                    className="mdi mdi-close-circle fs-3"
                    onClick={(e) => warnStopUpload(e)}
                  ></i>
                </button>
              </CardHeader>
              <CardBody className="upload-body" id="upload-body">
                {getBody()}
              </CardBody>
            </Card>
          </div>
        )}
        {children}
      </div>
      {/* <FailedUploads
        files={failedUploads}
        isOpen={showMessage}
        toggle={(p) => setShowMessage(!p)}
        onRetryUpload={handleOnRetryUpload}
      /> */}
      <FailedFilesDetails
        isOpen={showMessage}
        toggle={(p) => setShowMessage(!p)}
        batchIndex={failedDetailsBatchIndex}
        listUploadBatch={listUploadBatch}
      />
    </UploadProvider>
  );
};

const FailedFilesDetails = ({
  isOpen,
  toggle,
  listUploadBatch,
  batchIndex,
}) => {
  const batch = listUploadBatch[batchIndex];
  if (!batch) {
    return null;
  }
  return (
    <Modal className="upload-details" isOpen={isOpen} centered>
      <ModalHeader>Failed Files</ModalHeader>
      <ModalBody>
        <ListGroup>
          {batch.uploadStatus.map((item, idx) => {
            if (item === UPLOAD_STATUS.UPLOAD_ERROR) {
              return (
                <ListGroupItem
                  className="d-flex justify-content-between"
                  key={idx}
                >
                  <div>{batch.listUploadPayload[idx].filename} </div>
                  <div className="error">{batch.error[idx]}</div>
                </ListGroupItem>
              );
            }
          })}
        </ListGroup>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex gap-3">
          <Button onClick={toggle} className="btn btn-light">
            <i className="mdi mdi-close me-1 align-middle fs-4" /> Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Upload;
