import UploadZone from "components/Events/Client/Upload/upload_zone";
import Loader from "components/Loader";
import { getOpenEvent } from "helpers/jiraya_helper";
import { GET_EVENT_OPEN } from "helpers/jiraya_url";
import { useContext, useEffect } from "react";
import UserContext from "store/guest_user/userContext";
import useSWR from "swr";

const GuestUploadPage = ({ onUpload, onCancel, eventId }) => {
  const { user, handleOpenLoginModal } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      handleOpenLoginModal(onCancel);
    }
  }, [user]);
  return (
    <>
      <UploadZone onCancel={onCancel} onUpload={onUpload} eventId={eventId} />
    </>
  );
};

export default GuestUploadPage;
