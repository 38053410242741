import Loader from "components/Loader";
import createComponents from "helpers/createComponents";
import React, { Suspense, lazy, useCallback } from "react";

export function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        console.log("error in lazy loading component");
        console.log(error);
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
}

const SuspenseMiddleware = ({ Component, ...props }) => {
  // componentLoader({ lazyComponent: Component, attemptsLeft: 2 }).then(
  //   (comp) => {
  //     console.log(comp);
  //   }
  // );
  return (
    <Suspense
      fallback={
        <div className="loader loader-full">
          <Loader />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export const LAZY_RETRY = 3;
export default SuspenseMiddleware;
