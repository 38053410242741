const spacingFunction = (gridStyle) => {
  return (width) => {
    if (width < 768) {
      return gridStyle.spacing.laptop * 0.7;
    } else {
      return gridStyle.spacing.laptop;
    }
  };
};
const columnFunction = (gridStyle) => (containerWidth) => {
  if (containerWidth < 400) return gridStyle.columns.laptop - 2;
  if (containerWidth < 800) return gridStyle.columns.laptop - 1;
  return gridStyle.columns.laptop;
};

const targetRowHeightFunction = (gridStyle) => (containerWidth) => {
  if (containerWidth < 400) return gridStyle.targetRowHeight.laptop * 0.6;
  if (containerWidth < 800) return gridStyle.targetRowHeight.laptop * 0.9;
  return gridStyle.targetRowHeight.laptop;
};

const createGridFunctions = (gridStyle) => {
  const props = {};
  if (gridStyle.spacing) {
    props.spacing = spacingFunction(gridStyle);
  }
  if (gridStyle.columns) {
    props.columns = columnFunction(gridStyle);
  }
  if (gridStyle.targetRowHeight) {
    props.targetRowHeight = targetRowHeightFunction(gridStyle);
  }
  return props;
};

export { createGridFunctions };
