import { Col, Modal, ModalBody, Row } from "reactstrap";
import uploadImage from "assets/images/illustrations/guest-upload.svg";
import { useEffect, useState } from "react";

const UploadNotifyModal = ({ isGuestUploadAllowed, handleOpenGuestUpload }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isGuestUploadAllowed) {
      setTimeout(() => {
        setIsOpen(true);
      }, 10000);
    }
  }, []);

  return (
    <Modal centered size="md" isOpen={isOpen} className="gallery-modal">
      <ModalBody>
        <UploadNotify
          handleOpenGuestUpload={handleOpenGuestUpload}
          setIsOpen={setIsOpen}
          showImg={true}
          isModal={true}
        />
      </ModalBody>
    </Modal>
  );
};

export const UploadNotify = ({
  handleOpenGuestUpload,
  setIsOpen,
  showImg,
  isModal,
}) => {
  return (
    <div className="p-4 text-center">
      {isModal && (
        <div className="d-flex justify-content-end">
          <button
            className="btn close"
            onClick={() => setIsOpen(false)}
          ></button>
        </div>
      )}
      <h4>Upload Photos taken by You</h4>
      <div>Share and Showcase your perspective of the event</div>
      {showImg && (
        <Row className="justify-content-center">
          <Col md={9} sm={11}>
            <img src={uploadImage} className="img-fluid" />
          </Col>
        </Row>
      )}
      <Row>
        <button
          className="btn btn-gallery-primary  btn-rounded-2 mt-2"
          onClick={() => {
            setIsOpen && setIsOpen(false);
            handleOpenGuestUpload();
          }}
        >
          <i className="mdi mdi-upload" /> Upload Photos
        </button>
      </Row>
    </div>
  );
};
export default UploadNotifyModal;
