import { useState } from "react";
import { Input, Modal, ModalBody } from "reactstrap";
import { SocialNavColor } from "../Branding/SocialNav";
import FaceSearchForm from "../Forms/face_search";
import { BrandLogo } from "../Branding/BrandLogo";
import { useTranslation } from "react-i18next";

const FaceOnlyAccess = ({
  eventId,
  eventDetails,
  pin,
  accessKey,
  openFaceSearch,
  handleOnResponse,
  toggleOpenFaceSearch,
}) => {
  const { t } = useTranslation();
  const { branding, name, date } = eventDetails;
  return (
    <Modal
      className="modal-fullscreen"
      modalClassName="event-access-container gallery-cover gallery-modal face-only-access "
      isOpen={openFaceSearch}
      backdrop={false}
      scrollable={true}
      toggle={toggleOpenFaceSearch}
    >
      <ModalBody className="position-relative">
        {toggleOpenFaceSearch && (
          <div
            className="position-absolute top-0 end-0 p-2"
            onClick={toggleOpenFaceSearch}
          >
            <i className="mdi mdi-close fs-2" />
          </div>
        )}
        <div className="branding mb-3">
          <BrandLogo {...branding} className={"brand-sm"} />
        </div>

        <div className="event-details mb-4">
          <h4 className="event-name-access">{name}</h4>
          <h2 className="event-access-title">{t("Get Your Photos")}</h2>
        </div>
        <div className="p-3">
          <FaceSearchForm
            eventId={eventId}
            eventData={eventDetails}
            passkey={pin}
            accessKey={accessKey}
            onResponse={handleOnResponse}
          />
        </div>

        {branding && (
          <div className="branding mt-4">
            <small>Follow Us</small>
            <SocialNavColor brandingData={branding} iconSize={"15"} />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default FaceOnlyAccess;
