import React, { useContext, useEffect, useState, useCallback } from "react";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { useHistory, Link } from "react-router-dom";

import UploadContext from "store/image/uploadContext";

import classNames from "classnames";
import { UPLOAD_CHECK } from "../enums";
import UserContext from "store/guest_user/userContext";

const RETRY_LIMIT = 5;

const UploadZone = ({ eventId, onUpload, onCancel, collectionId }) => {
  const [lockUpload, setLockUpload] = useState(false);
  const getOs = useCallback(() => {
    const os = ["Windows", "Linux", "Mac"]; // add your OS values
    return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
  }, []);
  const OS = getOs();

  // check hidden files
  const skipHiddenFiles = (files) => {
    const nFiles = [];
    files.forEach((file) => {
      if (!file.name.startsWith(".")) {
        nFiles.push(file);
      }
    });
    console.log(
      `Hidden Files Skipped after ${nFiles.length} before ${files.length}`
    );
    return nFiles;
  };

  const { user } = useContext(UserContext);
  const handleAcceptedFiles = async (files) => {
    console.log("file added");
    files = skipHiddenFiles(files);
    uploadContext.handleAcceptedFiles(files, collectionId, user, eventId);
    onUpload();
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const uploadContext = useContext(UploadContext);

  const [source, setSource] = useState("image");
  const handleChangeSource = (source) => {
    if (source === "folder") {
      inputRef.current.setAttribute("webkitdirectory", true);
    } else {
      // inputRef.current.setAttribute("webkitdirectory", false);
      inputRef.current.removeAttribute("webkitdirectory");
    }
    setSource(source);
  };

  const { getRootProps, getInputProps, open, inputRef } = useDropzone({
    accept: "image/jpeg,image/png,image/jpg,image/webp",
    maxSize: 104857600,
    onDrop: (acceptedFiles) => {
      handleAcceptedFiles(acceptedFiles);
    },
    noClick: true,
  });

  useEffect(() => {
    if (inputRef.current && source === "folder") {
      inputRef.current.webkitdirectory = true;
    }
  }, []);

  useEffect(() => {
    return () => {
      console.log("unmount");
    };
  }, []);

  return (
    <Row className="gx-0">
      {" "}
      <Col className="col-12">
        <Card>
          <CardHeader>
            <div>
              <h3 className="card-title mb-0">
                <i className=" mdi mdi-upload align-middle fs-2 me-2" />
                Upload Images
              </h3>
              <small>Upload now OR Later from the event</small>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              <Row className="gx-0">
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />

                    <div className="mb-3">
                      {/* <i className="display-4 text-muted mdi mdi-video" /> */}
                      <i className=" display-4 text-muted mdi mdi-file-image" />
                    </div>
                    <div className="d-flex flex-column px-5 align-items-center gap-2">
                      <div>Drop files here </div>
                      <div>OR</div>
                      <Col md="12" sm="12">
                        <button className="btn btn-primary " onClick={open}>
                          Browse {source === "image" ? "Images" : "Folders"}
                        </button>
                      </Col>
                    </div>
                  </div>
                </div>
              </Row>
            </div>

            <div className="d-flex gap-2 justify-content-end">
              <button
                disabled={lockUpload}
                className="btn btn-soft-primary mt-3 px-5"
                onClick={handleOnCancel}
              >
                Cancel
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UploadZone;
