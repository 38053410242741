import { GET_UPLOADED_IMAGE_LIST } from "helpers/jiraya_url";
import CollectionGallery from "./collection_gallery";
import { getUploadedImageList } from "helpers/jiraya_helper";

const MyUploads = (props) => {
  const fetcherUrl = GET_UPLOADED_IMAGE_LIST;
  const fetcher = getUploadedImageList;
  const collection = null;
  return (
    <div>
      <CollectionGallery
        {...props}
        getImageParams={{
          fetcherUrl,
          fetcher,
        }}
      />
    </div>
  );
};

export default MyUploads;
