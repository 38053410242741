import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import BasicTheme from "./Basic";
import BookTheme from "./Book";
import VintageTheme from "./Vintage";
import ElegantTheme from "./Elegant";
import CeremonyTheme from "./Ceremony";
import FrameTheme from "./Frame";
import NoCoverTheme from "./NoCover";

import {
  SocialNavMonochromeBlack,
  SocialNavMonochrome,
  SocialNavColor,
} from "../Branding/SocialNav";
import MarathonTheme from "./Marathon";
import { getCoverImages } from "./helper";
import { GALLERY_MODE } from "../enums";
import { useTranslation } from "react-i18next";
import FlatColorCover from "./FlatColorCover";

const getCoverTheme = (name) => {
  switch (name) {
    case "basic":
      return BasicTheme;
    case "book":
      return BookTheme;
    case "vintage":
      return VintageTheme;
    case "elegant":
      return ElegantTheme;
    case "ceremony":
      return CeremonyTheme;
    case "frame":
      return FrameTheme;
    case "no-cover":
      return NoCoverTheme;
    case "marathon":
      return MarathonTheme;
    case "flat-color-cover":
      return FlatColorCover;
  }
};

const CoverTheme = ({
  name,
  children,
  deviceId,
  isCollectionLoading,
  galleryMode,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
  ...props
}) => {
  const { t } = useTranslation();
  const coverVarRef = createRef();

  const { colorPaletteStyle } = props;

  const SocialMediaNav = useMemo(() => {
    switch (colorPaletteStyle["cover-social-icon-color"]) {
      case "Black":
        return SocialNavMonochromeBlack;
      case "White":
        return SocialNavMonochrome;
      case "Color":
        return SocialNavColor;
      default:
        return SocialNavMonochrome;
    }
  }, [colorPaletteStyle]);

  const [isImgLoading, setIsImgLoading] = useState(true);
  const coverImgRef = createRef();
  const coverTextRef = createRef();
  const coverImgContainerRef = createRef();
  const coverImg = props.eventDetails?.cover_image_info?.path_dict.high_url;

  const onLoadCoverImg = () => {
    if (coverImgRef.current) {
      setIsImgLoading(false);
      coverImgRef.current?.classList?.add("op-1");
      coverTextRef.current?.classList?.add("op-1");
      coverImgContainerRef.current?.classList?.add("op-1");
    }
  };
  const themeName = useMemo(
    () =>
      props.eventDetails.cover_image_info == null ? "flat-color-cover" : name,
    [props.eventDetails]
  );

  const coverImageVersion = useMemo(
    () =>
      props.eventDetails.cover_image_info &&
      getCoverImages(props.coverPosition, props.eventDetails),
    [props.coverPosition, props.eventDetails]
  );

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    }
  }, [deviceId || 1]);

  var buttonTitle;
  var className;
  if (galleryMode === GALLERY_MODE.GALLERY) {
    buttonTitle = t("View Gallery");
    className = "gallery-mode-gallery";
  } else if (galleryMode === GALLERY_MODE.REGISTRATION) {
    buttonTitle = t("Get Event Photos");
    className = "gallery-mode-registration";
  } else {
    buttonTitle = t("Get Event Photos");
  }

  const ThemeComponent = useMemo(() => getCoverTheme(themeName), [themeName]);
  return (
    <div ref={coverVarRef} id="cover-vars" className={className}>
      {children}
      <ThemeComponent
        {...props}
        SocialMediaNav={SocialMediaNav}
        coverImgRef={coverImgRef}
        coverTextRef={coverTextRef}
        isImgLoading={isImgLoading}
        coverImgContainerRef={coverImgContainerRef}
        onLoadCoverImg={onLoadCoverImg}
        isMobile={isMobile}
        coverImageVersion={coverImageVersion}
        className={className}
        buttonTitle={buttonTitle}
        galleryMode={galleryMode}
        uploadButtonTitle={"Upload Photos"}
        isGuestUploadAllowed={isGuestUploadAllowed}
        handleOpenGuestUpload={handleOpenGuestUpload}
      />
    </div>
  );
};

export default CoverTheme;
